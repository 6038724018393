// @flow
import React from 'react';
import Page from 'component/Page';
import {H1} from 'dcme-style';
import {Box} from 'dcme-style/layout';
import {Flex} from 'dcme-style/layout';
import {Stack} from 'dcme-style/layout';
import {StackItem} from 'dcme-style/layout';
import {Image} from 'dcme-style/affordance';
import {Link} from 'dcme-style/affordance';
import {Text} from 'dcme-style/affordance';
import background from 'assets/background.jpg';

export default () => <Page>
    <Stack>
        <StackItem>
            <Image cover={2} src={background} />
        </StackItem>
        <StackItem>
            <Flex flexDirection="column" alignItems="center" height="100%">
                <Flex flexGrow="1" flexDirection="column" mb={3}>
                    <H1 fontSize="5rem" mt="auto">mosfez</H1>
                </Flex>
                <Flex flexGrow="1" flexDirection="column" alignItems="center">
                    <Box mb={2} mx={4}>
                        <Text as="div" textStyle="monospace" textAlign="center"><Link href="http://instagram.com/mosfez">Instagram</Link></Text>
                    </Box>
                    <Box mb={2} mx={4}>
                        <Text as="div" textStyle="monospace" textAlign="center"><Link href="https://felixcolgrave.bandcamp.com/track/h-m-remix">Hæm Remix, from Felix Colgrave's film Double King</Link></Text>
                    </Box>
                    <Box mb={2} mx={4}>
                        <Text as="div" textStyle="monospace" textAlign="center"><Link href="http://damienclarke.me/#Effects-pedals">Effects Pedals</Link></Text>
                    </Box>
                </Flex>
            </Flex>
        </StackItem>
    </Stack>
</Page>;
